<template>
	<w-layout column mb-5 mt-3>
		<w-flex>
			<p v-if="pendingConnections.length > 0" class="body-2 px-2">
				{{ $t('bill-scrapper.automations.pending_connection_title') }}
			</p>
			<PendingConnectionItem v-for="connection in pendingConnections" :key="connection.id" :value="connection" />
		</w-flex>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'
import BillScrapperModuleGuard from '@/mixins/ModulesGuards/BillScrapper/BillScrapperModuleGuard'

export default {
	name: 'PendingConnectionsList',
	components: {
		PendingConnectionItem: () => ({
			component: import('@/components/BillScrapper/Automations/PendingConnectionItem')
		})
	},
	mixins: [BillScrapperModuleGuard],
	data: function () {
		return {
			refreshHandler: null,
			pendingConnections: []
		}
	},
	computed: {
		...mapState({
			company: state => state.company.selected
		})
	},
	watch: {
		company: {
			handler: function (val) {
				if (val && val.id) {
					this.getPendingConnections()
				}
			},
			immediate: true
		}
	},
	mounted: function () {
		this.getPendingConnections()
	},
	beforeDestroy: function () {
		clearTimeout(this.refreshHandler)
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.PENDING_CONNECTION_CREATED, action: this.addPendingConnection },
				{ event: this.events.PENDING_CONNECTION_CANCELED, action: this.cancelOrClosePendingConnection },
				{ event: this.events.PENDING_CONNECTION_UPDATED, action: this.cancelOrClosePendingConnection }
			]
		},
		/**
		 * @param {object} pendingConnection
		 */
		addPendingConnection: function (pendingConnection) {
			this.pendingConnections.push(pendingConnection)

			this.sortedPendingConnections()
			this.refreshPendingConnections()
		},
		/**
		 * @param {number} pendingConnectionId
		 */
		cancelOrClosePendingConnection: function (pendingConnectionId) {
			const index = this.pendingConnections.findIndex(connection => connection.id === pendingConnectionId)
			if (!isNaN(index)) {
				this.pendingConnections.splice(index, 1)
			}

			this.sortedPendingConnections()
		},
		getPendingConnections: async function () {
			this.pendingConnections.clear()
			this.service
				.getPendingConnections()
				.then(connectionsList => {
					this.pendingConnections = connectionsList
				})
				.then(() => {
					this.sortedPendingConnections()
					this.refreshPendingConnections()
				})
		},
		refreshPendingConnections: function () {
			if (this.pendingConnections.length > 0 && this.refreshHandler === null) {
				this.refreshHandler = setTimeout(async () => {
					await Promise.all(
						this.pendingConnections.map(r => {
							return this.service.updatePendingConnection(r.id)
						})
					)
					this.refreshHandler = null
					this.refreshPendingConnections()
				}, 2000)
			}
		},
		sortedPendingConnections: function () {
			const collator = new Intl.Collator(this.$i18n.locale)

			this.pendingConnections.sort((connectionA, connectionB) => {
				return collator.compare(connectionA.provider.name, connectionB.provider.name)
			})
		}
	}
}
</script>
